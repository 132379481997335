import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";
import {
  ICompanyNameVerificationDTO,
  IInvitePartnerDTO,
  IReInvitationDTO,
} from "@/models/SupplyChainManagement";
import { IResponse } from "@/services/axios/entities";
import { getEnv } from "@/utils/storage";

const SUPPLY_CHAIN = "SupplyChains";
const TENANTS = "Tenants";
export class SupplyChainManagementAPI extends HttpClient {
  constructor(private env = getEnv()) {
    super(env.VUE_APP_DOMAIN_API_SERVER);
  }

  public invitePartner = async (
    data: IInvitePartnerDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${SUPPLY_CHAIN}/send-invitation-code`, data)
      .catch(catchAxiosError);

    return response;
  };

  public companyNameVerification = async (
    data: ICompanyNameVerificationDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANTS}/tenant/name`, { data })
      .catch(catchAxiosError);

    return response;
  };

  public getSupplyChainsManagement = async (
    data: any,
    params: any
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SUPPLY_CHAIN}/${data}`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public checkRole = async (): Promise<any> => {
    const response: any = await this.instance
      .get(`${TENANTS}/tenant/payment-type/verify`)
      .catch(catchAxiosError);

    return response;
  };

  public getProgressChartData = async (params: any): Promise<IResponse> => {
    const response: any = await this.instance
      .get(`${SUPPLY_CHAIN}/${params.year}/chart`, {
        params: {
          MonthType: params.MonthType,
        },
      })
      .catch(catchAxiosError);

    return response;
  };

  public getLineChartData = async (): Promise<IResponse> => {
    const response: any = await this.instance
      .get(`${SUPPLY_CHAIN}/performance-trend`, {})
      .catch(catchAxiosError);

    return response;
  };

  public getBarChartData = async (params: any): Promise<IResponse> => {
    const response: any = await this.instance
      .get(`${SUPPLY_CHAIN}/supplier/barchart/${params.year}`, {})
      .catch(catchAxiosError);

    return response;
  };

  public getTotalInvitationData = async (): Promise<IResponse> => {
    const response: any = await this.instance
      .get(`${SUPPLY_CHAIN}/supplier/count`, {})
      .catch(catchAxiosError);

    return response;
  };

  public verifyInvitedFile = async (params: any): Promise<IResponse> => {
    const response: any = await this.instance
      .post(`${SUPPLY_CHAIN}/invitation/file/verify`, params, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .catch(catchAxiosError);

    return response;
  };

  public downloadInvitationTemplate = async (): Promise<IResponse> => {
    const response: any = await this.instance
      .get(`${SUPPLY_CHAIN}/invitation/template/download`)
      .catch(catchAxiosError);

    return response;
  };

  public sendInvitation = async (params: any): Promise<IResponse> => {
    const response: any = await this.instance
      .post(`${SUPPLY_CHAIN}/invitation/file/send`, params)
      .catch(catchAxiosError);

    return response;
  };

  public resendInvitation = async (
    params: IReInvitationDTO
  ): Promise<IResponse> => {
    const response: any = await this.instance
      .put(
        `${SUPPLY_CHAIN}/${params.TenantSupplyChainId}/re-invitation`,
        params
      )
      .catch(catchAxiosError);

    return response;
  };
}
