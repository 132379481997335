import HttpClient from "@/services/axios/instance";

import { catchAxiosError } from "@/services/axios/error";

import { IResponse } from "@/services/axios/entities";
import { getEnv } from "@/utils/storage";

import {
  IChangePasswordForm,
  ICreateUpdateUserForm,
  IUpdateUserProfileForm,
  IRegisterDTO,
  IVerifyOtpDTO,
  IUpdatePassword,
  ICreateUserIdentityRequest,
} from "@/models/User";
import { ICreateUpdateCustomerForm } from "@/models/Customer";

const AUTH = "Auth";
const Account = "Account";
export class AccountAPI extends HttpClient {
  constructor(token?: string, private env = getEnv()) {
    super(env?.VUE_APP_DOMAIN_API_SERVER, token);
  }

  public login = async (data: any): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/login`, data)
      .catch(catchAxiosError);

    return response;
  };

  public createAccount = async (
    user: ICreateUpdateCustomerForm | ICreateUpdateUserForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(AUTH, user)
      .catch(catchAxiosError);

    return response;
  };

  public createUserServer = async (
    user: ICreateUserIdentityRequest
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/create-user`, user)
      .catch(catchAxiosError);

    return response;
  };

  public updateUserProfile = async (
    user: IUpdateUserProfileForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/me`, user)
      .catch(catchAxiosError);

    return response;
  };

  public fetchAccountProfile = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${AUTH}`)
      .catch(catchAxiosError);

    return response;
  };

  public fetchAccountIdentityProfile = async (
    tenantId?: string
  ): Promise<IResponse> => {
    const url = tenantId
      ? `${AUTH}/identity-info?tenantId=${tenantId}`
      : `${AUTH}/identity-info`;

    const response: IResponse = await this.instance
      .get(url)
      .catch(catchAxiosError);

    return response;
  };

  public changePassword = async (
    data: IChangePasswordForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/password`, data)
      .catch(catchAxiosError);

    return response;
  };

  public registerRedeem = async (data: IRegisterDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/register`, data)
      .catch(catchAxiosError);

    return response;
  };

  public forgotPassword = async (data: {
    Email: string;
  }): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/forgot-password`, data)
      .catch(catchAxiosError);

    return response;
  };

  public verifyOtp = async (data: IVerifyOtpDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/verify-otp`, data)
      .catch(catchAxiosError);

    return response;
  };

  public updatePassword = async (data: IUpdatePassword): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${AUTH}/update-password`, data)
      .catch(catchAxiosError);
    return response;
  };

  public getAccount = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${Account}`)
      .catch(catchAxiosError);
    return response;
  };
}
